//Copyright 2021, Nathaniel Smith, Ron Arel, All rights reserved.
import "./Who.css"
import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import background from "../backgrounds/WorldMap2.svg"
import { useRef, useLayoutEffect, onScroll } from 'react';
import arrow from "../backgrounds/arrow.svg"
import { useEffect } from 'react';
import AboutInfo from './AboutInfo';
import FooterOne from './FooterOne';

const currentDate = new Date();

const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();



const Who = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,

  ...props
}) => {
  const [alpha, setAlpha] = useState(1)
  const [alphaOne, setAlphaOne] = useState(1)
  const [alphaTwo, setAlphaTwo] = useState(1)
  const [alphaThree, setAlphaThree] = useState(1)

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   
  const onScroll = () => {
    //console.log(1 - (document.getElementById("isomeRandomIDd").scrollTop/400));
    //console.log((1 - ((document.getElementById("isomeRandomIDd").scrollTop/400))));
    setAlphaThree(((1 + (window.innerHeight*6)/380)- ((document.getElementById("isomeRandomIDd").scrollTop/400))));
    setAlphaTwo(((1 + (window.innerHeight*4)/380)- ((document.getElementById("isomeRandomIDd").scrollTop/400))));
    setAlphaOne(((1 + (window.innerHeight*2)/380)- ((document.getElementById("isomeRandomIDd").scrollTop/400))));
    setAlpha(1 - (document.getElementById("isomeRandomIDd").scrollTop/400));
  }



  const outerClasses = classNames(
    'hero section center-content ',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );
  const useStyles = makeStyles({
    flexGrow: {
      flex: '1',
    },
    border: {
      color: "#FFFFF",
      borderRadius: 90, 
      backgroundColor: "transparent", 
      borderWidth: 3, 
      borderColor: '#daa520',
      border: 'solid'
    
  }})

  const classes = useStyles()
  const who = "About."


  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;


  return (
    <div style={{backgroundColor: "#dfe8e8"
}}>
    <div style={{height: 60}}></div>
    <div    className="About" style={{backgroundColor: "#dfe8e8", paddingBottom: 50}} >
    <div    className="About-banner"
    style={{backgroundImage: `url(${background})`, backgroundRepeat : 'repeat', position: "",   maxWidth: "1000px",    backgroundSize: "cover"}}
    >
      <div className="d-flex align-items-center justify-content-center" style={{height: "inherit"}} >
      <div
        {...props}
        className={outerClasses}
      >
            <div className="hero-content">
              <div style={{display: "flex", justifyContent: "center"}}> 
            {/* <img style={{position: "absolute", padding: "20px", height: "70px", width: "120px", top: "90vh", opacity: alpha}}  src={arrow}/> */}
            </div>

                <h1 key={who} style={{fontSize: 100, color: "#dfe8e8",fontFamily: "Outfit", fontWeight: "600"}}>
                {who}
                </h1>
            </div>
     
      </div>
      </div>
 
    </div>
    <AboutInfo isMobile={isMobile}></AboutInfo> 
    </div>
    </div>
  );
}



export default Who;