//Copyright 2021, Nathaniel Smith, Ron Arel, All rights reserved.

import React from 'react';
import Who from '../components/Who';
import { useEffect } from 'react';
import FooterOne from '../components/FooterOne';
const currentDate = new Date();


const About = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  });


  return (
    <div style={{backgroundColor: "#dfe8e8",    position:"absolute",
    top:0,
    left:0,
    bottom:0,
    right:0,
    height:"100%",
    width:"100%",
    }}>
      
      <Who className="illustration-section-01" />
      <div style={{
  bottom: 0, left: 0, right: 0, position:"fixed", justifyContent:"center",     display: "inline"}}>
      <FooterOne></FooterOne>
      </div>
    </div>

  );
}

export default About;



// import './About.css';
// import React, { Component } from "react";
// import { Navigate } from "react-router-dom";
// import banner from "./../assets/stanford_stock.jpg"




// class About extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//     };
//   }


//   render() {
//   return (
//     <div className='About-outside'> 
//     <div className="About">
//         <img src={banner} className='About-banner'>
        
//         </img>
//         <p>
//         Steinhall is a long-standing education firm providing premier academic counsel to high school students across the country. 
//         Headquartered in Palo Alto, Steinhall has provided (until now) private tutoring and mentorship services to high-achieving 
//         students throughout much of the Silicon Valley. Offering both online and in-person services, Steinhall has curated a select
//          group of top Ivy League students/alumni who provide mentoring across a range of academic, professional, and social needs,
//           with services personally tailored to each client.
//         </p>

//         <p>
//         Steinhall prides itself in its bespoke service and flexibility, tailoring each offering to fit a client’s specific needs,
//          no matter the scope. Mentor and student are perfectly matched to ensure the highest quality of tutelage and the development 
//          of a close mentor-mentee relationship - crucial for bringing out the student’s highest potential, as an academic scholar and
//           a fully-developed individual. 
//         </p>

//         <h1>Network</h1>
        
//         <p>Building relationships is a core part of anyone’s personal and professional life, and at Steinhall our mentors are exclusively
//              assigned to a single client, allowing them to build a close personal connection with the student. Steinhall has built a 
//              network of mentors specialized in providing high-quality academic tutoring and mentorship. We holistically recruit mentors 
//              from a variety of disciplines and backgrounds, ensuring the highest quality academic tutelage. Steinhall mentors are the 1% 
//              of the 1%, and a majority are top students from Ivy League and Ivy Plus universities. </p>
//     </div>
//     </div>
//   );
//   }
// }

// export default About;
