
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast, style } from 'react-toastify';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';
import './Contact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ivy from "./../backgrounds/ivy-grey.png";
const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast.success('Form sent!', {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { first, last, number, email, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        first, last, number,
        email,
        message
      };

      // Use emailjs to email contact form data
      await emailjs.send(
        "service_dem917n",
        "template_aa10y6b",
        templateParams,
        "user_SVEAmfaRowdttfCemJjZA"
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
    <div className='Contact' style={{backgroundColor: "#dfe8e8" }}>
      <div className='Contact-container' style={{backgroundColor:"#012c32", padding: "20px", 
      paddingRight: "40px",paddingLeft: "40px", border: "8px solid #023d45"}}>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
                <h1 className='Contact-inquire'> Inquire About Admission </h1>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='first'
                      {...register('first', {
                        required: {
                          value: true,
                          message: 'Please enter your first name'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        } 
                      })}
                      className='form-control formInput'
                      placeholder='First Name'
                    ></input>
                    {errors.first && <span className='errorMessage'>{errors.first.message}</span>}
                  </div>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='last'
                      {...register('last', {
                        required: {
                          value: true,
                          message: 'Please enter your last name'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        } 
                      })}
                      className='form-control formInput'
                      placeholder='Last Name'
                    ></input>
                    {errors.last && <span className='errorMessage'>{errors.last.message}</span>}
                  </div>
                </div>
                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='tel'
                      name='number'
                      {...register('number', {
                        required: true,
                        pattern:
                          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
                      })}
                      className='form-control formInput'
                      placeholder='Phone Number'
                    ></input>
                    {errors.number && (
                      <span className='errorMessage'>Please enter a valid phone number</span>
                    )}                  </div>
                  <div className='col-6'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email address'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                 <div className='row formRow'>
                  <div className='col'>
                    <textarea
                      style={{height: 200, resize:"none"}}
                      rows={3}
                      
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder='Message'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>

                <button className='submit-btn' disabled={disabled} type='submit'>
                  Submit
                </button>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactForm;
