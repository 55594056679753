const ColoredLine = ({ color, height=5, opacity=1, marginT=0, marginB=0, marginR=0, marginL=0, width=1000 }) => (
    <hr
        style={{
            color: color,
            height: height,
            opacity: opacity,
            marginTop: marginT,
            marginBottom: marginB,
            marginRight: marginR,
            marginLeft: marginL,
            maxWidth: width,
            marginLeft: "auto",
            marginRight: "auto",

        }}
    />
);


export default ColoredLine;