import './FooterOne.css';
import React, {useState, useEffect} from "react";
import logo from './../blackoldtextlogoaligned.png';
import { Link } from "react-router-dom";
import $ from 'jquery';
import logo2 from './../backgrounds/AntiqueLogoNoTextDark.svg'
import ColoredLine from './ColoredLine';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer, toast, style } from 'react-toastify';

function openMail() {
  window.open('mailto:inquire@steinhall.com')
}
const toastifySuccess = () => {
  toast.error('Machine Not Registered', {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'notifyToast'

    });
};
const onSubmit = () => {
  // Destrcture data object
  try {
    // Disable form while processing submission
    toastifySuccess();
  } catch (e) {
    console.log(e);
  }
};

// $(window).scroll(function(e){ 
//   var $el = $('.footer'); 
//   var isPositionFixed = ($el.css('position') == 'fixed');
//   if ($(this).scrollTop() > 200 && !isPositionFixed){ 
//     $el.css({'position': 'fixed', 'top': '0px'}); 
//   }
//   if ($(this).scrollTop() < 200 && isPositionFixed){y
//     $el.css({'position': 'static', 'top': '0px'}); 
//   } 
// });
// $(window).scroll(function(e){ 
//   var $el = $('.footer'); 
//   var $el2 = $('.child'); 

//   var isPositionFixed = ($el.css('position') === 'fixed');
//   if ($(this).scrollTop() > window.innerHeight - 100
//   && !isPositionFixed){ 
//     $el.css({'position': 'fixed', 'top': '0px', 'bottom': '', "background-color": "#012c32"}); 
//     $el2.css({"color": "#dfe8e8"}); 
//   }
//   if ($(this).scrollTop() < window.innerHeight - 100
//   && isPositionFixed){
//     $el.css({'position': 'absolute', 'bottom': '0px', 'top': '', "background-color": "transparent"}); 
//     $el2.css({"color": "black"}); 
//   } 
// });



function Footer() {

  
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  

  return (
  <div style={{backgroundColor: "#dfe8e8"}}>
    <ColoredLine color="#d0d1d3" height={1} width={1000}> </ColoredLine>
  <div className="footer">

         <div className="loginDiv" style={{marginLeft:10}}> 
         <Link to="/">
            <img src={logo2} style={{ top: "0px", left: "0px", height: "4vh", margin: "10px 10px",}}></img>
         </Link>

         <Link to="/about"> 
          
          <button 
          
          className="child" 
          style={{
            backgroundColor: "transparent",
            color: "#012c32",
            fontSize: "20px",
            padding: "10px",
            fontSize: "17px",
            margin: "10px 10px",
            cursor: "pointer",
            border: "transparent"

          }}
          >About</button> </Link> 

          {isMobile && <div class="footer-break"></div>}

         <div > <button className="child"           style={{
            backgroundColor: "transparent",
            color: "#012c32",
            fontSize: "20px",
            padding: "10px",
            fontSize: "17px",
            margin: "10px 10px",
            cursor: "pointer",
            border: "transparent"
            
          }} onClick={openMail}>Contact</button></div>


          
          <button 
          
          className="child" 
          onClick={() => toastifySuccess()}
          style={{
            backgroundColor: "transparent",
            color: "#012c32",
            fontSize: "20px",
            padding: "10px",
            fontSize: "17px",
            margin: "10px 10px",
            cursor: "pointer",
            border: "transparent"

          }}
          >Login</button>

         
         </div>
         <div className="loginDiv"> 

         <Link to="/tos" target="_blank"> 
          
          <button 
          
          className="child" 
          style={{
            backgroundColor: "transparent",
            color: "#012c32",
            fontSize: "20px",
            padding: "10px",

            fontSize: "17px",
            margin: "10px 10px",
            cursor: "pointer",
            border: "transparent"

          }}
          >Terms</button> </Link> 

             <Link to="/privacypolicy" target="_blank"> 
          
          <button 
          
          className="child" 
          style={{
            backgroundColor: "transparent",
            color: "#012c32",
            fontSize: "20px",
            padding: "10px",
            fontSize: "17px",
            margin: "10px 10px",
            cursor: "pointer",
            border: "transparent"

          }}
          >Privacy</button> </Link> 

          </div>
  </div>
  <ToastContainer />

  </div> 
  );
        }

export default Footer;