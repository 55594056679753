import logo from './../blackoldtextlogoaligned.png';
import './Homepage.css';
import background from "./../backgrounds/Ocean-4.5s-3000px2.svg";
import transparentBack from "./../backgrounds/transparent.svg";
import { Link } from 'react-router-dom';
import { Button, useState, useEffect, useRef } from 'react';
import Contact from '../components/Contact';
import Services from '../components/Services';
import FooterOne from '../components/FooterOne';
import HeaderOne from '../components/HeaderOne';

import logo2 from './../backgrounds/PureWhiteAntiqueLogocropped.svg'


function openMail() {
  window.open('mailto:inquire@steinhall.com?subject=Subject&body=Body%20goes%20here')
}


function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const myRef = useRef(null)
  function handleScroll() {
    //myRef.current.scrollIntoView()    
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  } 
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth'})    
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  if (isMobile) {
    return (
    <div>

      <div className='Homepage-ygg'> 
      
      <div className="App" 
      
      style={{ backgroundImage: `url(${transparentBack})`, height: "98vh", backgroundSize: "cover", backgroundPosition: "center bottom"}}>
        <header className="App-header">
        {/* <img src={logo2} style={{ top: "0px", left: "0px", position: "absolute", height: "7vh"}}/> */}
        </header>
        <div className="container">
      
          <div className="child" style={{paddingTop: 90}}> <h1  className="mobile-quote">" The world exists for the education of each man. "</h1></div>
          <div className="child" style={{}}> <h2 className="mobile-ralph">- Ralph Waldo Emerson</h2></div>
            
          </div>
      </div>
      </div> 
      <div ref={myRef}> 
        <Services isMobile={isMobile}/>
      </div>
          <Contact/>
          <FooterOne></FooterOne>
    </div>
    );
  }

  return (
    <div>
    
   <div className='Homepage-ygg'> 
   
    <div className="App" 
    
    style={{ backgroundImage: `url(${transparentBack})`, height: "98vh", backgroundSize: "cover", backgroundPosition: "center bottom",}}>
           {/* <HeaderOne></HeaderOne> */}

      <div className="container">
       <img src={logo2} style={{ top: "0px", left: "0px", position: "absolute", height: "10vh", padding: "10px", marginTop: "20px", marginLeft: "20px"}}></img>

        <div className="child" style={{paddingTop: 240}}> <h1  className="quote">" The world exists for the <br/> education of each man. "</h1></div>
        <div className="child" style={{paddingLeft: 500, paddingBottom: 60}}> <h2 className="ralph">- Ralph Waldo Emerson</h2></div>
          
          <svg height="60" width="320" xmlns="http://www.w3.org/2000/svg">
            <rect className="shape" height="60" width="320" />
          </svg>
          <div onClick={executeScroll} className="Homepage-btntext">BEGIN</div>
        </div>
    </div>
    </div> 
    <div ref={myRef}> 
      <Services isMobile={isMobile}/>
    </div>
        <Contact/>
        <FooterOne></FooterOne>
    </div>
  );
}

export default Homepage;
