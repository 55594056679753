import './Login.css';
import React, { Component } from "react";
import background from "./../270b6738_1.png";
import Header from "./../Header.js";
import { Navigate } from "react-router-dom";
import FooterOne from '../components/FooterOne';
import logo from "./../backgrounds/AntiqueLogoTextDark.svg"

function openMail() {
  window.open('mailto:inquire@steinhall.com?subject=Subject&body=Body%20goes%20here')
}



class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      loginParams: {
        email: "",
        password: ""
      },
      errors: {
        incorrect: false
      },

    };
  }

  handleFormChange = event => {

    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew
    });
  };
 
  login = event => {
    var placeholder = this.state.errors
    placeholder.incorrect = true
    this.setState({errors: placeholder})
    let email_id = this.state.loginParams.email;
    let user_password = this.state.loginParams.password;
    if (email_id === "eqwrqfadsasfdfbgdafgshgsfwaa" && user_password === "wqeqtweqtwqtewy4352443524365748657w46etrafsdsg") {
      localStorage.setItem("token", "T");
      this.setState({
        islogged: true
      });
    }
    event.preventDefault();
  };

  render() {
    if (localStorage.getItem("token")) {
      localStorage.clear();
      return <Navigate to="/" />;
    }
  return (
      <div className="Login-container">
        
        <div className='Login-card'>
          <img src={logo} className='Login-logo'></img>
          <form onSubmit={this.login}>
              <div className="Login-form">
              <label>
                  <input className="Login-formInput" onChange={this.handleFormChange} 
                  placeholder="Email" type="text" name="email" />
              </label>
              </div>

              <div className="Login-form">
              <label>
                  <input className="Login-formInput" onChange={this.handleFormChange} 
                  placeholder="Password" type="text" name="password" />
              </label>
              </div>
              <div className='Login-error-box'>
              {this.state.errors.incorrect && <span className='errorMessage'>This machine is not registered</span>}
              </div>
              <div className="Login-submit">
                <input className="Login-submit-btn" type="submit" value="Log In"/>
              </div>
            </form>
            
        </div>

        <FooterOne></FooterOne>

        </div>
      
  );
  }
}

export default Login;
