import './Services.css';
import React, { Component } from "react";
import ColoredLine from "./ColoredLine"
import { Link } from 'react-router-dom';



class Services extends Component {
    constructor(props) {
      super(props);
      this.state = {
          isMobile: this.props.isMobile,
          descMargin: this.props.isMobile ? 20 : 0,
          headMargin: this.props.isMobile ? 20 : 0,
          allignText: this.props.isMobile ? "center" : "left"

      };
    }

    render() {

        const headStyle = {
            marginLeft: this.state.descMargin, 
            marginRight: this.state.descMargin,
            textAlign: this.state.allignText
        };

        const descStyle = {
            marginLeft: this.state.descMargin, 
            marginRight: this.state.descMargin,
        };



      return (<div className="Services">
          
        <div className='Services-box'>
        <div className='Services-who'>
            <div className='Services-div'>
                <h1 style={{textAlign: "center", marginBottom: 10, fontSize: "60px", 
                marginLeft: this.state.descMargin, marginRight: this.state.descMargin,}} className='Services-header'>
                We. Create. Outliers.
                </h1>

                </div>
                
                <div className='Services-div'>
                    <p style={{paddingRight: 0, color: "#4e4e4e", fontSize: "14px"}, descStyle} className='Services-desc'>
                    At Steinhall, our greatest conviction is our belief in anyone’s ability to become an Outlier. Modern day school 
                    systems hammer in the necessity of structure and a single-minded focus on narrow academic goals. But those who truly 
                    excel go beyond this system, making real-world impact and becoming truly differentiated. Our firm looks to build students
                     into individuals with initiative and agency, empowering future academics, leaders, and visionaries.  

                    </p>
            </div>
        </div>

        <ColoredLine color="#4e4e4e" height={1} marginB={30} marginT={5} opacity={1} />

        <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Academic Mentorship  
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                Our firm provides a full suite of<a style={{color: "#262626"}}> academic tutoring in any subject</a>, supported by  <a style={{color: "#262626"}}>a personal Ivy League mentor</a>. We look to further our client’s academic potential and instruct them on building stronger workflow habits for use throughout their academic and professional lives. 
                </p>
            </div>
        </div>
        
        <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Proficiency Development
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                We understand that our clients are exceptional and interested in areas beyond the restrictive academic system. Clients are matched to mentors based on their familiarity with these <a style={{color: "#262626"}}>additional interests.</a> However, if further expertise is necessary, clients can connect with a subject expert via our  <a style={{color: "#262626"}}> expansive network of industry professionals and scholars.</a>
                </p>
            </div>
        </div>
        
        <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Professional Development
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                Outside of academic help, our mentors are experienced in providing professional and social guidance to develop students into confident and proactive leaders. Our mentors are not your standard education professionals, but  <a style={{color: "#262626"}}>  rather rocket scientists, venture fellows, hackers, and founders: </a> Outliers who have gone through a high school system that promotes conformity and emerged as so much more, Outliers that have been admitted to the  <a style={{color: "#262626"}}> best universities in the world.</a> Steinhall makes sure that its clients are capable of doing the same.
                </p>
            </div>
        </div>
        <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Project Advisors
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                Our firm facilitates the creation of <a style={{color: "#262626"}}>extraordinary student-driven projects</a> by providing industry expertise, operations management/guidance, and a suite of tools/personnel. Steinhall Project Advisors are hand-selected and have all made extraordinary impacts during their own time in high school, ranging from  <a style={{color: "#262626"}}>founding international non-profits with thousands of members, to building web applications with millions of monthly users.</a> By the end of this semester-long commitment, clients will look to have developed a project that is large and impressive in scope, reaching multiple states/nations and hundreds/thousands of people. 
                </p>
            </div>
        </div>

        <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Headhunting
                </h1>

            </div>
            
            <div className='Services-div' style={{marginBottom: 0}}>
                <p className='Services-desc' style={descStyle}>
                Beyond our immediate network, we pride ourselves in our ability to find the right person for any given circumstance. Our headhunting service can <a style={{color: "#262626"}}> source and hire a mentor matching any qualifications requested by the client. </a>Steinhall directors work closely with clients every step of the way, making sure the recruiting process is seamless and customized on  <a style={{color: "#262626"}}> a client-to-client basis. </a>               </p>
            </div>
        </div>

    
        {/* <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Project Advisors
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                A belief in potential is Steinhall’s greatest conviction. The cornerstone of our firm is our ability to elevate students to their greatest potential. This entails a focus on character - building a student up as an academic, leader, and professional. One of the best ways for students to gain life experience, make an impact, and pursue their passions is by creating something new. But agency is hard for high school students who have followed a system their whole lives. By pairing clients with project advisors and firm resources, Steinhall facilitates the creation of extraordinary student-driven projects, providing industry expertise, operations management/guidance, and a suite of tools and personnel to scale and magnify a student’s personal endeavor. Steinhall Project Advisors are hand-selected and have all made extraordinary impacts during their own time in high school, ranging from founding international non-profits with thousands of members, to building web applications with millions of monthly users. Beginning with initial project plans, our advisors will be there every step of the way, working closely with clients to make their dream a reality. By the end of this semester-long commitment, clients will look to have developed a project that is large and impressive in scope, reaching multiple states/nations and hundreds/thousands of people. 
                </p>
            </div>
        </div> */}

        <div style={{marginBottom: 0}} className='Services-container'>
            
            
        </div >

        <div className="Services-linebox-container">

        <div className="Services-linebox"> 
            <div className='Services-linebox' style={{borderLeft: "2px solid #012c32"}}></div>
            <div className='Services-linebox' style={{borderTop: "2px solid #012c32"}}></div>
        </div>

        <Link to="/about"><button className='Services-about-btn'>More About Us</button></Link>

        <div className="Services-linebox"> 
            <div className='Services-linebox' style={{borderRight: "2px solid #012c32"}}></div>
            <div className='Services-linebox' style={{borderTop: "2px solid #012c32"}}></div>
        </div>

        </div>

        </div>
      </div>)
    }

}




export default Services;