import React from 'react';
import ReactDOM from 'react-dom';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import Homepage from './views/Homepage';
import Login from "./views/Login"
import TOS from "./views/TOS"
import PrivacyPolicy from "./views/PrivacyPolicy"
import About from "./views/About"
import HeaderOne from './components/HeaderOne';


import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Homepage />} />
      {/* <Route path="login" element={<Login />} /> */}
      <Route path="/tos" element={<TOS />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/about" element={<About />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
