import './Services.css';
import React, { Component } from "react";
import ColoredLine from "./ColoredLine"
import { Link } from 'react-router-dom';



class AboutInfo extends Component {
    constructor(props) {
      super(props);
      this.state = {
          isMobile: this.props.isMobile,
          descMargin: this.props.isMobile ? 20 : 0,
          headMargin: this.props.isMobile ? 20 : 0,
          allignText: this.props.isMobile ? "center" : "left"

      };
    }

    render() {

        const headStyle = {
            marginLeft: this.state.descMargin, 
            marginRight: this.state.descMargin,
            textAlign: this.state.allignText
        };

        const descStyle = {
            marginLeft: this.state.descMargin, 
            marginRight: this.state.descMargin,
        };



      return (<div className="Services">
          
        <div className='Services-box'>
        <div className='Services-who'>
            <div className='Services-div'>


                </div>
                
                <div className='Services-div' style={{marginTop: -10}}>
                    <p style={{paddingRight: 0, color: "#4e4e4e", fontSize: "14px"}, descStyle} className='Services-desc'>
                    Steinhall is a long-standing education firm providing <a style={{color: "#262626"}}>premier academic counsel </a> to high school students across the country. Headquartered in Palo Alto, Steinhall has provided (until now) private tutoring and mentorship services to high-achieving students throughout much of the Silicon Valley. Offering both online and in-person services. Steinhall has curated a select group of <a style={{color: "#262626"}}>top Ivy League students/alumni</a> who provide mentoring across a range of <a style={{color: "#262626"}}>academic, professional, and social needs </a>, with services <a style={{color: "#262626"}}>personally tailored to each client.</a> Mentor and student are perfectly matched to ensure the highest quality of tutelage and the development of a close mentor-mentee relationship - crucial for bringing out the student’s highest potential, as an academic scholar and a fully-developed individual. 
                    <br></br>

                    </p>
            </div>
            <div className='Services-div' style={{marginTop: 20}}>
                    <p style={{paddingRight: 0, color: "#4e4e4e", fontSize: "14px"}, descStyle} className='Services-desc'>
                    <a style={{color: "#262626"}}>Confidentiality is emphasized. </a>Due to the nature of some of the firm’s clientele, Steinhall guarantees discreteness and privacy. All mentors sign non-disclosure agreements ensuring these standards.
                    </p>
            </div>
            <div className='Services-div' style={{marginTop: 20}}>
                    <p style={{paddingRight: 0, color: "#4e4e4e", fontSize: "14px"}, descStyle} className='Services-desc'>
                    Building relationships is a core part of anyone’s personal and professional life, and at Steinhall our mentors are <a style={{color: "#262626"}}>exclusively assigned to a single client</a> , allowing them to build a close personal connection with the student. Steinhall has built a network of mentors specialized in providing high-quality academic tutoring and mentorship. We holistically recruit mentors from a variety of disciplines and backgrounds, ensuring the highest quality academic tutelage. Mentors are the <a style={{color: "#262626"}}>1% of the 1%, and a majority are top students from Ivy League and Ivy Plus universities. </a>                    </p>
            </div>


        </div>

        {/* <div className='Services-container'>
            
            <div className='Services-div'>
                <h1 className='Services-header' style={headStyle}>
                Project Advisors
                </h1>

            </div>
            
            <div className='Services-div'>
                <p className='Services-desc' style={descStyle}>
                A belief in potential is Steinhall’s greatest conviction. The cornerstone of our firm is our ability to elevate students to their greatest potential. This entails a focus on character - building a student up as an academic, leader, and professional. One of the best ways for students to gain life experience, make an impact, and pursue their passions is by creating something new. But agency is hard for high school students who have followed a system their whole lives. By pairing clients with project advisors and firm resources, Steinhall facilitates the creation of extraordinary student-driven projects, providing industry expertise, operations management/guidance, and a suite of tools and personnel to scale and magnify a student’s personal endeavor. Steinhall Project Advisors are hand-selected and have all made extraordinary impacts during their own time in high school, ranging from founding international non-profits with thousands of members, to building web applications with millions of monthly users. Beginning with initial project plans, our advisors will be there every step of the way, working closely with clients to make their dream a reality. By the end of this semester-long commitment, clients will look to have developed a project that is large and impressive in scope, reaching multiple states/nations and hundreds/thousands of people. 
                </p>
            </div>
        </div> */}

        <div style={{marginBottom: 100}} className='Services-container'>
            
            
        </div >

      

        </div>
      </div>)
    }

}




export default AboutInfo;